import Layout from 'components/layout';
import Seo from 'components/seo';
import React from 'react';
import Blog from 'sections/blog';
import LinkrocketDetail from '../section-linkrocket/linkrocket-details';
import Banner from '../section-linkrocket/banner';
export default function LinkrocketPage() {
    return (
        <Layout>
            <Seo
                title="AdelphaTech"
                description="is a leading web & mobile application development firm based in Toronto, Canada that offers custom web, and mobile app development services."
            />
            <Banner />
            <LinkrocketDetail />
            <Blog />
        </Layout>
    )
};