/** @jsx jsx */
import Image from 'components/image';
import { graphql, useStaticQuery } from 'gatsby';
import { Col } from 'react-bootstrap';
import { Box, Container, Heading, Text, jsx } from 'theme-ui';


const PunchlistDetail = () => {
    const data = useStaticQuery(graphql`
    query {
      ImageLinrocket: file(relativePath: { eq: "Linkrocket.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, PNG])
        }
      }
      ImageLinkrocketDesign: file(relativePath: {eq: "Linkrocket-design.png"}) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, PNG], quality: 100)
        }
      }
    }
  `);
    return (
        <Box as="section" id="Blog_Details" sx={styles.section}>
            <Container>
                <Col xl="9" lg="9" md="10" className='mx-auto'>
                    <Image
                        src={data.ImageLinrocket.childImageSharp.gatsbyImageData}
                        loading="lazy"
                        alt="cbc"
                        className="rounded"
                    />
                </Col>
                <Col xl="7" lg="7" md="10" className="mx-auto mb-5">
                    <Heading sx={styles.title}>
                        About LinkRocket
                    </Heading>
                    <Text as="p" sx={styles.summary}>
                        LinkRocket is a powerful tool designed to boost your website's performance and drive more traffic. Our comprehensive solution is tailored to elevate your ranking and increase visibility.
                    </Text>
                    <Heading sx={styles.title}>
                        The Challenge
                    </Heading>
                    <Text as="p" sx={styles.summary} className='mb-4'>
                        Enhancing website performance and attracting more visitors can be daunting. LinkRocket addresses this challenge by providing a user-friendly solution that simplifies the process of boosting traffic and improving rankings.
                    </Text>
                    <Text as="p" sx={styles.summary} className='mb-4'>
                        Our goal was to create a solution that seamlessly integrates with your existing website infrastructure, making it easy to implement and manage. With LinkRocket, you can optimize your website for better performance without the need for complex technical knowledge.
                    </Text>
                    <Heading sx={styles.title}>
                        The Process
                    </Heading>
                    <Text as="p" sx={styles.summary} className='mb-4'>
                        Developing LinkRocket involved a thorough understanding of website optimization techniques and user needs. We conducted extensive research and analysis to ensure that our solution meets the diverse requirements of website owners.
                    </Text>
                    <Heading sx={styles.title}>
                        Designing For Website Owners
                    </Heading>
                    <Heading sx={styles.title}>
                        Small Business Owners
                    </Heading>
                    <Text as="p" sx={styles.summary} className='mb-4'>
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item bg-transparent">Owners of small businesses looking to increase online visibility</li>
                            <li class="list-group-item bg-transparent">Seeking a cost-effective solution to improve website ranking</li>
                            <li class="list-group-item bg-transparent">Require a simple and efficient tool to manage website performance</li>
                        </ul>
                    </Text>
                    <Heading sx={styles.title}>
                        Digital Marketers
                    </Heading>
                    <Text as="p" sx={styles.summary} className='mb-4'>
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item bg-transparent">Marketing professionals aiming to enhance website traffic and engagement</li>
                            <li class="list-group-item bg-transparent">Looking for a comprehensive solution to optimize website performance</li>
                            <li class="list-group-item bg-transparent">Need tools for tracking and analyzing website traffic</li>
                        </ul>
                    </Text>
                </Col>
                <Col xl="7" lg="7" md="10" className="mx-auto pb-5">
                    <Heading sx={styles.title}>
                        Development Approach
                    </Heading>
                    <Text as="p" sx={styles.summary} className='mb-4'>
                        Our approach to developing LinkRocket focused on creating a solution that not only boosts website performance but also enhances user experience. We began by analyzing industry trends and user behavior to understand the key factors that contribute to a successful website.
                    </Text>
                    <Text as="p" sx={styles.summary} className='mb-4'>
                        Our goal was to design a platform that integrates seamlessly with existing websites, providing users with a hassle-free experience. We aimed to deliver a solution that not only improves website rankings but also drives organic traffic growth.
                    </Text>
                    <Heading sx={styles.title}>
                        Key Features
                    </Heading>
                    <Text as="p" sx={styles.summary} className='mb-4'>
                        LinkRocket offers a range of features designed to elevate your website's performance. From advanced SEO tools to comprehensive analytics, our platform provides everything you need to rank higher and attract more visitors.
                    </Text>
                    <Text as="p" sx={styles.summary} className='mb-4'>
                        Our platform is constantly updated to adapt to changing search engine algorithms, ensuring that your website remains competitive in the ever-evolving digital landscape. With LinkRocket, you can stay ahead of the curve and achieve your website goals.
                    </Text>
                </Col>
                <Col xl="9" lg="9" md="10" className='mx-auto'>
                    <Image
                        src={data.ImageLinkrocketDesign.childImageSharp.gatsbyImageData}
                        loading="lazy"
                        alt="Webhelp formation"
                        className=""
                    />
                </Col>
                <Col xl="7" lg="7" md="10" className="mx-auto py-5">
                    <Heading sx={styles.title}>
                        Streamlined Workflow
                    </Heading>
                    <Text as="p" sx={styles.summary} className='mb-4'>
                        At LinkRocket, we understand the importance of a streamlined workflow. Our platform not only boosts your website's ranking but also simplifies the process of managing and optimizing your content.
                    </Text>
                    <Text as="p" sx={styles.summary} className='mb-4'>
                        With LinkRocket, you can easily analyze your website's performance, identify areas for improvement, and implement changes to enhance your SEO strategy. Our platform provides you with the tools you need to stay ahead of the competition and achieve your digital marketing goals.
                    </Text>
                    <Heading sx={styles.title}>
                        Seamless Integration
                    </Heading>
                    <Text as="p" sx={styles.summary} className='mb-4'>
                        LinkRocket seamlessly integrates with your existing website, allowing you to optimize your content without disrupting your workflow. Our platform is designed to work with all major content management systems, making it easy to incorporate into your existing setup.
                    </Text>
                    <Text as="p" sx={styles.summary} className='mb-4'>
                        With LinkRocket, you can elevate your website's ranking and drive more traffic, all while maintaining a seamless and efficient workflow. Try LinkRocket today and experience the difference it can make for your website.
                    </Text>
                </Col>
            </Container>
        </Box >
    );
};

export default PunchlistDetail;

const styles = {
    section: {
        backgroundColor: '#f3f6fe',
        pt: [8, null, 12, 6, null, 10],
        pb: [null, null, null, 8, 0],
    },
    grid: {
        gap: [null, null, null, null, 12],
        display: ['flex', null, null, 'grid'],
        flexDirection: ['column-reverse', null, null, 'unset'],
        alignItems: 'center',
        gridTemplateColumns: ['1fr', null, null, null, '470px 1fr', '1fr 549px'],
    },
    illustration: {
        textAlign: 'center',
        position: 'relative',
        mt: [2, null, null, 0, 4, 0],
        img: {
            maxWidth: ['100%', null, null, null, null, ''],
        },
    },
    content: {
        marginTop: [null, null, null, null, null, -16],
        maxWidth: [null, null, null, 420, 560, 'none'],
        margin: [null, null, null, '0 auto', 'unset'],
        textAlign: ['center', null, null, null, 'left'],
        mb: 4
    },
    titlePhase: {
        color: '#ec1c23',
        fontFamily: 'headingAlt',
        fontSize: [4, null, null, 8, null, null, 14],
        fontWeight: 500,
        lineHeight: [1.33, null, 1.4, 1.2],
        letterSpacing: ['-0.5px', null, null, '-1px'],
        mb: 3,
        mt: 6,
        span: {
            backgroundSize: 'cover',
            px: 2,
        },
    },
    title: {
        color: '#09386b',
        fontFamily: 'headingAlt',
        fontSize: [4, null, null, 8, null, null, 14],
        fontWeight: 500,
        lineHeight: [1.33, null, 1.4, 1.2],
        letterSpacing: ['-0.5px', null, null, '-1px'],
        mb: 3,
        mt: 6,
        span: {
            backgroundSize: 'cover',
            px: 2,
        },
    },
    summary: {
        color: 'textSecondary',
        fontFamily: 'headingAlt',
        fontSize: ['15px', null, null, 2, '15px', 4],
        lineHeight: [1.86, null, null, null, 1.86, 1.5],
        m: [null, null, null, '20px auto 0', '15px 0 0', null, 'unset'],
    },
    list: {
        gap: '0 18px',
        fontFamily: 'headingAlt',
        gridTemplateColumns: ['repeat(2, 142px)', null, null, 'repeat(2, 200px)'],
        justifyContent: [null, null, null, 'center', 'unset'],
        listStyle: 'none',
        display: 'block',
        mt: [4, null, null, 5, 4, 5],
        p: 0,
        li: {
            fontSize: [0, 1, null, 2, '15px', 2],
            fontWeight: 600,
            alignItems: 'center',
            color: 'textSecondary',
            fontFamily: 'headingAlt',
            display: 'flex',
            lineHeight: [2.81, null, null, null, 2.2, 2.81],
        },
    },
};
